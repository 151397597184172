import { HttpMethods } from '../../enums';
import { restApi, Tags } from '../common';

import { GetInvestorEntitiesRequest, GetInvestorEntitiesResponse } from './investor-entities.model';

export const investorEntitiesApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.InvestorEntities],
  })
  .injectEndpoints({
    endpoints: build => {
      const getInvestorEntities = build.query<
        GetInvestorEntitiesResponse,
        GetInvestorEntitiesRequest
      >({
        query: () => {
          return {
            url: `/api/investor/public/investor-entities`,
            method: HttpMethods.Get,
            params: {},
          };
        },
        providesTags: [Tags.InvestorEntities],
      });

      return {
        getInvestorEntities,
      };
    },
  });
