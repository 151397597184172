export enum Tags {
  AdviceSuggestions = 'ADVICE_SUGGESTIONS',
  AdviceManagedPortfolio = 'ADVICE_MANAGED_PORTFOLIO',

  AtomicGatewayCompletionLink = 'ATOMIC_GATEWAY_COMPLETION_LINK',

  AutoInvestPreferences = 'AUTO_INVEST_PREFERENCES',

  BankAccount = 'BANK_ACCOUNT',

  CampaignStatus = 'CAMPAIGN_STATUS',
  Campaigns = 'CAMPAIGNS',

  Communication = 'COMMUNICATION',

  DeleteAccountRequest = 'DELETE_ACCOUNT_REQUEST',

  DictionaryAssetClasses = 'DICTIONARY_ASSET_CLASSES',

  DistributionReinvestment = 'DISTRIBUTION_REINVESTMENT',

  FeatureEnvironments = 'FEATURE_ENVIRONMENTS',

  FomoMetrics = 'FOMO_METRICS',

  ManagedPortfolioEligibility = 'MANAGED_PORTFOLIO_ELIGIBILITY',

  InvestmentEligibility = 'INVESTMENT_ELIGIBILITY',
  InvestmentRequest = 'INVESTMENT_REQUEST',
  InvestmentRequestBulk = 'INVESTMENT_REQUEST_BULK',
  Investments = 'INVESTMENTS',
  InvestmentSign = 'INVESTMENT_SIGN',
  InvestmentSignBulk = 'INVESTMENT_SIGN_BULK',
  InvestmentLiquidation = 'INVESTMENT_LIQUIDATION',
  InvestmentUpdatesSubscription = 'INVESTMENT_UPDATES_SUBSCRIPTION',
  InvestmentTrackingUpdatesSubscription = 'INVESTMENT_TRACKING_UPDATES_SUBSCRIPTION',
  InvestmentTrackingDetailedUpdatesSubscription = 'INVESTMENT_TRACKING_DETAILED_UPDATES_SUBSCRIPTION',
  InvestmentTrackingSubmitTransfer = 'INVESTMENT_TRACKING_SUBMIT_TRANSFER',

  InvestorAccount = 'INVESTOR_ACCOUNT',
  InvestorAccountNoteConfig = 'INVESTOR_ACCOUNT_NOTE_CONFIG',
  InvestorAccountWallet = 'INVESTOR_ACCOUNT_WALLET',
  InvestorProfile = 'INVESTOR_PROFILE',

  InvestorEntities = 'INVESTOR_ENTITIES',

  InvestorEntity = 'INVESTOR_ENTITY',
  InvestorEntityDocuments = 'INVESTOR_ENTITY_DOCUMENTS',
  InvestorEntityProvider = 'INVESTOR_ENTITY_PROVIDER',
  InvestorEntityStatus = 'INVESTOR_ENTITY_STATUS',

  Ira = 'IRA',

  Marketplace = 'MARKETPLACE',
  MarketplacePaginatedOfferings = 'MARKETPLACE_PAGINATED_OFFERINGS',
  MarketplaceAudiences = 'MARKETPLACE_AUDIENCES',
  MarketplaceMetrics = 'MARKETPLACE_METRICS',
  MarketplaceProgress = 'MARKETPLACE_PROGRESS',
  MarketplaceRecommendations = 'MARKETPLACE_RECOMMENDATIONS',

  Offering = 'OFFERING',
  OfferingDetails = 'OFFERING_DETAILS',
  OfferingEligibility = 'OFFERING_ELIGIBILITY',
  OfferingPromo = 'OFFERING_PROMO',
  NoteDocuments = 'NOTE_TENDER_OFFER_DOCUMENTS',
  NoteTenderOfferConfig = 'NOTE_TENDER_OFFER_CONFIG',
  PaymentMethods = 'PAYMENT_METHODS',
  PenscoServiceFunds = 'PENSCO_SERVICE_FUNDS',

  PortfolioAllocationTimeline = 'PORTFOLIO_ALLOCATION_TIMELINE',
  PortfolioSelfAudience = 'PORTFOLIO_SELF_AUDIENCE',
  PortfolioDocuments = 'PORTFOLIO_DOCUMENTS',
  PortfolioDocumentsYears = 'PORTFOLIO_DOCUMENTS_YEARS',
  PortfolioGetDocuments = 'PORTFOLIO_GET_DOCUMENTS',
  PortfolioInvestmentStrategy = 'PORTFOLIO_INVESTMENT_STRATEGY',
  PortfolioInvestments = 'PORTFOLIO_INVESTMENTS',
  PortfolioInvestmentsActivityYears = 'PORTFOLIO_INVESTMENTS_ACTIVITY_YEARS',
  PortfolioInvestmentsByAssetClass = 'PORTFOLIO_INVESTMENTS_BY_ASSET_CLASS',
  PortfolioInvestmentsByOffering = 'PORTFOLIO_INVESTMENTS_BY_OFFERING',
  PortfolioInvestmentsByStatus = 'PORTFOLIO_INVESTMENTS_BY_STATUS',
  PortfolioInvestmentRolloverAmount = 'PORTFOLIO_INVESTMENT_ROLLOVER_AMOUNT',
  PortfolioNavChart = 'PORTFOLIO_NAV_CHART',
  PortfolioOverview = 'PORTFOLIO_OVERVIEW',
  PortfolioPayments = 'PORTFOLIO_PAYMENTS',
  PortfolioTiers = 'PORTFOLIO_TIERS',
  PortfolioTiersOptIn = 'PORTFOLIO_TIERS_OPT_IN',
  PortfolioTransactionPayments = 'PORTFOLIO_TRANSACTION_PAYMENTS',
  PortfolioPaymentsYears = 'PORTFOLIO_PAYMENTS_YEARS',
  PortfolioStatements = 'PORTFOLIO_STATEMENTS',
  PortfolioStatementsProviders = 'PORTFOLIO_STATEMENTS_PROVIDERS',
  PortfolioSynapseStatements = 'PORTFOLIO_SYNAPSE_STATEMENTS',
  PortfolioPromotion = 'PORTFOLIO_PROMOTION',

  UserState = 'USER_STATE',
  UserContact = 'USER_CONTACT',
  UserProfile = 'USER_PROFILE',
  UserProfileAddress = 'USER_PROFILE_ADDRESS',
  UserBeneficiaries = 'USER_BENEFICIARIES',
  UserTwoFactorAuthStatus = 'USER_TWO_FACTOR_AUTH_STATUS',

  Quiz = 'QUIZ',

  Representative = 'REPRESENTATIVE',
  SupportCenter = 'SUPPORT_CENTER',

  UserReports = 'USER_REPORTS',
  UserReportsYears = 'USER_REPORTS_YEARS',
  UsersRecurringPayments = 'USERS_RECURRING_PAYMENTS',

  Wordpress = 'WORDPRESS',

  Address = 'ADDRESS',

  Push = 'PUSH',

  PlaidLinkBank = 'PLAID_LINK_BANK',

  UnitApplication = 'UNIT_APPLICATION',
  UnitKYCMigrationStatus = 'UNIT_KYC_MIGRATION_STATUS',
  UnitBankLinkMigrationStatus = 'UNIT_BANK_LINK_MIGRATION_STATUS',

  ProviderKYCMigrationStatus = 'PROVIDER_KYC_MIGRATION_STATUS',
  ProviderBankLinkMigrationStatus = 'PROVIDER_BANK_LINK_MIGRATION_STATUS',

  PlaidKYCVerificationStatus = 'PLAID_IDENTITY_VERIFICATION_STATUS',

  EtcPurchaseRequest = 'ETC_PURCHASE_REQUEST',

  MarketingLinks = 'MARKETING_LINKS',
}
